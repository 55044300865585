<script lang="ts" setup>
import type { TypeSpCompany } from "~/types/csv/TypeSp";
import type { TypeChartCompany } from "~/types/TypeChartCompany";
import type { TypeCrtCompany } from "~/types/csv/TypeCrt";
import { addAlpha } from "~/lib/company-profile/colours.js";
import type { TypePpiCompany } from "~/types/csv/TypePpi";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";
import type { TypeProject } from "~/types/contentful";

const props = withDefaults(
  defineProps<{
    items?: TypeChartCompany[];
    company?: TypeCrtCompany | TypeSpCompany | TypePpiCompany | null;
    project: TypeProject<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
    titleLeft: string;
    maxScore: number;
    minScore: number;
    valueHighRed?: boolean;
    scenario?: string;
    orderedBy?: string;
    isDoubleChart?: boolean;
    year?: string;
    yearSelected?: string;
    isBarChart?: boolean;
    oneFieldOnly?: boolean;
    stepSize?: number;
    xKeys?: { title: string; position: string }[];
    bgStrips?: NonNullable<
      NonNullable<TypeDataSourceParsed["scoring"]>["grading"]
    >[string];
    selectedOutlook?: string;
    selectedKpi?: string;
    showPillars?: boolean;
    pillars?: { key: string; colour: string; maxResult: number }[];
    forceHoverIndex?: number;
  }>(),
  {
    items: () => [],
    company: null,
    maxScore: 100,
    minScore: -40,
    titleLeft: "",
    valueHighRed: true,
    scenario: "",
    stepSize: 10,
    orderedBy: "",
    isBarChart: false,
    year: "",
    yearSelected: "2030",
    xKeys: () => [],
    bgStrips: () => [],
    selectedOutlook: undefined,
    selectedKpi: undefined,
    showPillars: false,
    forceHoverIndex: -1,
  },
);

const scrollX = ref(0);
const updateScrollX = (event: Event) =>
  (scrollX.value = (event.target as HTMLElement).scrollLeft);

const stripCount = computed(() => {
  return (props.maxScore - props.minScore) / props.stepSize;
});

const stripHeight = computed(() => {
  return 100 / stripCount.value;
});

const hoverIndex = ref<number>(-1);
const hoverX = ref<number>(0);
const hoverY = ref<number>(0);
const hoveredCompany = ref<TypeCrtCompany | TypeSpCompany | TypePpiCompany>();

watch(() => props.forceHoverIndex, (current, previous) => {
  if (current > -1) {
    hoverIndex.value = current;
    hoveredCompany.value = props.items[current]?.csv;
    hoverX.value = 100;
    hoverY.value = -360;
  } else {
    hoverIndex.value = -1;
  }
});

const stripValues = computed(() => {
  return Array.from(
    { length: stripCount.value + 1 },
    (_v, k) => props.maxScore - k * props.stepSize,
  );
});

const positionY = (value: number | string) => {
  const range = props.maxScore - props.minScore;
  return ((parseInt(value as string) - props.minScore) * 100) / range;
};

const barHeight = (values: NonNullable<TypeChartCompany["values"]>) => {
  return Math.abs(
    positionY(values[values.length - 1]?.to ?? 0) - positionY(values[0]?.from ?? 0),
  );
};

const gradientHeight = (item: TypeChartCompany) => {
  const bau = positionY(Number(item.valueBAU));
  const low = positionY(Number(item.valueLow));
  const high = positionY(Number(item.valueHigh));

  if (props.valueHighRed) {
    return high > bau ? high - bau : bau - high;
  } else {
    return low - bau;
  }
};

const onHover = (event: Event, item: TypeChartCompany, index: number) => {
  hoverIndex.value = index;
  hoveredCompany.value = item.csv;
  hoverX.value = (event.target as HTMLElement).offsetLeft;
  hoverY.value = -(event as MouseEvent).offsetY;
};
</script>

<template>
  <div class="the-chart grow pb-8 ps-1 pt-4">
    <div
      class="chart flex"
      :class="{
        'chart--high-red': valueHighRed,
        'chart--double': isDoubleChart,
      }"
      :data-scenario="scenario"
    >
      <div class="relative h-[360px] w-full overflow-visible">
        <div
          class="chart__inner h-full"
          :class="{ scrollable: items.length }"
          @scroll="updateScrollX($event)"
        >
          <div
            v-if="titleLeft"
            class="chart__title-left pointer-events-none absolute size-full text-xs font-semibold"
          >
            <div
              class="absolute left-[-44px] top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90"
            >
              {{ titleLeft }}
            </div>
          </div>

          <div
            class="absolute left-0 top-0 size-full overflow-hidden border border-ui-grey2"
            :class="{
              '!rounded-br-none !border-t-0': bgStrips,
              'rounded-r-[20px]': !isDoubleChart,
            }"
          >
            <div
              v-for="(s, index) in stripCount"
              :key="'strip' + s"
              class="chart__strip absolute w-full bg-ui-grey4 [&:not(:first-of-type)]:border-t [&:not(:first-of-type)]:border-t-ui-grey2"
              :class="bgStrips.length > 0 && '!bg-white opacity-30'"
              :style="{
                top: stripHeight * index + '%',
                height: stripHeight + '%',
              }"
              :data-value="stripValues[index]"
            />

            <template v-if="bgStrips">
              <div
                v-for="strip in bgStrips"
                :key="strip.text + strip.colour"
                class="chart__strip chart__strip--bg absolute w-full rounded-tr-[20px] border-t-2 border-t-white bg-ui-grey4 before:absolute before:right-4 before:top-0 before:block before:h-full before:w-[2px] before:border-b-[20px] before:border-b-white before:bg-white before:content-[''] after:absolute after:bottom-[-20px] after:right-0 after:z-[-1] after:block after:size-[20px] after:bg-[inherit] after:content-['']"
                :style="{
                  bottom: positionY(strip.min) + '%',
                  height: positionY(strip.max) - positionY(strip.min) + '%',
                  backgroundColor: addAlpha(strip.colour, 0.15),
                }"
                :data-value="strip.min"
              >
                <div
                  class="absolute right-1 h-full before:absolute before:right-[-3px] before:top-full before:z-0 before:block before:size-4 before:rounded-tr-[20px] before:bg-white before:content-['']"
                >
                  <span
                    class="absolute left-1/2 top-1/2 -ml-2 inline-block -translate-x-1/2 -translate-y-1/2 rotate-90 whitespace-nowrap pl-[12px] text-xs"
                  >
                    <i
                      class="absolute left-[-3px] top-[2px] inline-block size-[11px] rounded-full"
                      :style="{ backgroundColor: strip.colour }"
                    />
                    {{ strip.text === "Best Practice" ? "BP" : strip.text }}
                  </span>
                </div>
              </div>
            </template>

            <div
              v-if="orderedBy"
              class="absolute right-0 top-0 w-[170px] border-b border-l border-b-ui-grey2 border-l-ui-grey2 bg-white py-[10px] text-center text-xs font-semibold"
              :class="{ 'max-sm:hidden': isDoubleChart }"
            >
              Ordered by {{ orderedBy }}
            </div>

            <div
              v-if="year"
              class="absolute -top-px left-[calc(50%-32px)] w-6 border border-ui-grey2 bg-white p-1 text-center text-xs font-semibold"
            >
              {{ year }}
            </div>
          </div>

          <div
            v-if="titleLeft"
            class="absolute left-[-48px] flex h-full w-[50px] flex-col justify-between border-r-2 border-r-dark-blue-grey-two text-right"
          >
            <div
              v-for="value in stripValues"
              :key="value"
              class="pr-[2px] text-xs font-semibold"
            >
              {{ value }}%
            </div>
          </div>

          <section
            class="chart__items relative flex h-full justify-between"
            :class="{ 'scrollable': items.length, 'pr-4': bgStrips }"
            @mouseleave="hoverIndex = -1"
          >
            <ChartUiInfo
              v-if="hoveredCompany"
              :company="hoveredCompany"
              :project="project"
              :show="hoverIndex !== -1"
              :style="{
                left: Math.max(66, hoverX - 16) + 'px',
                bottom: hoverY + 350 + 'px',
              }"
              :year="yearSelected"
              :is-bar-chart="isBarChart"
              :max-score="maxScore"
              :selected-outlook="selectedOutlook"
              :selected-kpi="selectedKpi"
              :show-pillars="showPillars"
              :pillars="pillars"
            />
            <article
              v-for="(item, i) in items"
              :key="i"
              class="chart__item relative flex-auto text-center"
              @mouseenter="onHover($event, item, i)"
            >
              <div
                v-if="
                  !isBarChart
                    && typeof item.valueBAU !== 'undefined'
                    && typeof item.valueHigh !== 'undefined'
                    && typeof item.valueLow !== 'undefined'
                "
                class="absolute size-full"
              >
                <ChartUiGradient
                  :type="valueHighRed ? 'bau-low' : 'high-bau'"
                  :bottom="
                    positionY(valueHighRed ? item.valueLow : item.valueHigh)
                      + '%'
                  "
                  :height="
                    positionY(item.valueBAU)
                      - positionY(valueHighRed ? item.valueLow : item.valueHigh)
                      + '%'
                  "
                />
                <ChartUiGradient
                  :type="valueHighRed ? 'high-bau' : 'bau-low'"
                  :bottom="positionY(item.valueBAU) + '%'"
                  :height="gradientHeight(item) + '%'"
                />

                <ChartUiDot
                  type="high"
                  :bottom="positionY(item.valueHigh) + '%'"
                />
                <ChartUiDot
                  type="bau"
                  :bottom="positionY(item.valueBAU) + '%'"
                />
                <ChartUiDot
                  type="low"
                  :bottom="positionY(item.valueLow) + '%'"
                />
              </div>

              <div
                v-else-if="isBarChart && item.values"
                class="absolute size-full"
              >
                <ChartUiBar
                  :values="item.values"
                  :bottom="positionY(item.values[0]?.from ?? 0) + '%'"
                  :height="barHeight(item.values) + '%'"
                  :draw-border="oneFieldOnly"
                />

                <div
                  v-if="item.median"
                  class="bar-line absolute w-full"
                  :style="{ bottom: positionY(item.median) + '%' }"
                >
                  <hr
                    class="z-[3] m-auto !mb-[-1.2px] h-[2px] w-1/2 max-w-[40px] rounded-[4px] border-0 bg-dark-blue-grey-two"
                  />
                </div>
              </div>
            </article>

            <svg
              v-if="company && xKeys && 'EBIT 2020' in company"
              preserveAspectRatio="none"
              view-box="0 0 100 100"
              transform="scale(1, -1)"
              class="line-graph absolute size-full"
            >
              <line
                v-for="(xKey, x) in xKeys"
                :key="x"
                :x1="xKey.position"
                y1="0"
                :x2="xKey.position"
                y2="100%"
                stroke="#d8d8d8"
                stroke-width="1"
              />

              <line
                :x1="xKeys[0]?.position"
                :y1="
                  positionY(scenario == 'EBIT' ? company['EBIT 2020'] : 0) + '%'
                "
                :x2="xKeys[1]?.position"
                :y2="
                  positionY(
                    company[`${scenario} 2030 High` as keyof TypeCrtCompany],
                  ) + '%'
                "
                stroke="#ff3736"
                stroke-width="3"
              />
              <line
                :x1="xKeys[1]?.position"
                :y1="
                  positionY(
                    company[`${scenario} 2030 High` as keyof TypeCrtCompany],
                  ) + '%'
                "
                :x2="xKeys[2]?.position"
                :y2="
                  positionY(
                    company[`${scenario} 2050 High` as keyof TypeCrtCompany],
                  ) + '%'
                "
                stroke="#ff3736"
                stroke-width="3"
              />
              <line
                :x1="xKeys[0]?.position"
                :y1="
                  positionY(scenario == 'EBIT' ? company['EBIT 2020'] : 0) + '%'
                "
                :x2="xKeys[1]?.position"
                :y2="
                  positionY(
                    company[`${scenario} 2030 BAU` as keyof TypeCrtCompany],
                  ) + '%'
                "
                stroke="#ff962a"
                stroke-width="3"
              />
              <line
                :x1="xKeys[1]?.position"
                :y1="
                  positionY(
                    company[`${scenario} 2030 BAU` as keyof TypeCrtCompany],
                  ) + '%'
                "
                :x2="xKeys[2]?.position"
                :y2="
                  positionY(
                    company[`${scenario} 2050 BAU` as keyof TypeCrtCompany],
                  ) + '%'
                "
                stroke="#ff962a"
                stroke-width="3"
              />
              <line
                :x1="xKeys[0]?.position"
                :y1="
                  positionY(scenario == 'EBIT' ? company['EBIT 2020'] : 0) + '%'
                "
                :x2="xKeys[1]?.position"
                :y2="
                  positionY(
                    company[`${scenario} 2030 Low` as keyof TypeCrtCompany],
                  ) + '%'
                "
                stroke="#63c954"
                stroke-width="3"
              />
              <line
                :x1="xKeys[1]?.position"
                :y1="
                  positionY(
                    company[`${scenario} 2030 Low` as keyof TypeCrtCompany],
                  ) + '%'
                "
                :x2="xKeys[2]?.position"
                :y2="
                  positionY(
                    company[`${scenario} 2050 Low` as keyof TypeCrtCompany],
                  ) + '%'
                "
                stroke="#63c954"
                stroke-width="3"
              />
            </svg>

            <hr
              v-if="isBarChart"
              class="absolute z-[3] m-0 !mb-[-1.2px] h-[2px] w-full border-0 bg-dark-blue-grey-two"
              :style="{ bottom: positionY(0) + '%' }"
            />
          </section>

          <section
            class="chart__item-titles absolute h-[165px] !w-[calc(100%+64px)] overflow-hidden pr-6 max-sm:max-w-full"
            :class="{ 'pr-[88px]': bgStrips }"
          >
            <div
              class="flex justify-between"
              :style="{ marginLeft: scrollX * -1 + 'px' }"
            >
              <article
                v-for="(item, i) in items"
                :key="i"
                class="chart__item-title relative flex flex-auto tracking-widest"
              >
                <div
                  class="absolute left-[calc(50%-3px)] mr-[-50%] origin-left rotate-[58deg] whitespace-nowrap text-xs uppercase max-md:text-[10px]"
                >
                  {{ item.name }}
                </div>
              </article>
            </div>
          </section>

          <section
            v-if="xKeys && xKeys.length"
            class="absolute w-full"
          >
            <article
              v-for="(key, k) in xKeys"
              :key="k"
              class="absolute ml-[-15px] mt-1 w-[30px] text-xs font-semibold tracking-widest"
              :style="{
                left: key.position,
              }"
            >
              {{ key.title }}
            </article>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  &:not(.chart--double) .chart__inner.scrollable {
    @apply max-sm:w-[calc(100vw_-_52px)] max-sm:overflow-auto;
  }

  &:not(.chart--double) .chart__items.scrollable,
  &:not(.chart--double) .chart__item-titles > .flex {
    @apply max-sm:min-w-[1024px];
  }

  &__item-title,
  &__item {
    @apply first-of-type:ml-3 last-of-type:mr-3;
  }

  &--high-red {
    &:deep(.ci-value--high) span {
      @apply bg-level-high;
    }

    &:deep(.ci-value--low) span {
      @apply bg-level-best-practice;
    }

    &:deep(.ci-gradient--high-bau) span {
      @apply bg-gradient-to-b from-level-high to-level-medium;
    }

    &:deep(.ci-gradient--bau-low) span {
      @apply bg-gradient-to-b from-level-medium to-level-best-practice;
    }
  }

  &[data-scenario="Net Zero Aligned"],
  &[data-scenario="BAU"],
  &[data-scenario="High Climate Impact"] {
    &:deep(.ci-gradient--high-bau) span,
    &:deep(.ci-gradient--bau-low) span {
      @apply bg-ui-grey2;

      background-image: none;
    }
  }

  &[data-scenario="Net Zero Aligned"] {
    &:deep(.ci-value--high) span,
    &:deep(.ci-value--bau) span {
      @apply z-[1] bg-ui-grey2;

      background-image: none;
    }
  }

  &[data-scenario="BAU"] {
    &:deep(.ci-value--high) span,
    &:deep(.ci-value--low) span {
      @apply z-[1] bg-ui-grey2;

      background-image: none;
    }
  }

  &[data-scenario="High Climate Impact"] {
    &:deep(.ci-value--low) span,
    &:deep(.ci-value--bau) span {
      @apply z-[1] bg-ui-grey2;

      background-image: none;
    }
  }
}

.bar-line {
  transition: bottom 0.4s ease;
}
</style>
