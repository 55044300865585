<script lang="ts" setup>
import type { TypeSpCompany } from "~/types/csv/TypeSp";
import type { TypeCrtCompany } from "~/types/csv/TypeCrt";
import type { TypePpiCompany } from "~/types/csv/TypePpi";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import { Colours } from "~/lib/company-profile/colours.js";
import { isCompanyKey } from "~/lib/company-profile/type-guards.js";
import type { TypeProject } from "~/types/contentful";

const props = defineProps<{
  company: TypeCrtCompany | TypeSpCompany | TypePpiCompany;
  project: TypeProject<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  show?: boolean;
  isBarChart?: boolean;
  year: string;
  selectedOutlook?: string;
  selectedKpi?: string;
  maxScore?: number;
  showPillars?: boolean;
  pillars?: { key: string; colour: string; maxResult: number }[];
}>();

const isCrtCompany = (company: TypeCrtCompany | TypeSpCompany | TypePpiCompany): company is TypeCrtCompany => props.project.fields.slug == "climate-risk-tool";

const colours = new Colours(
  "Strategy" in props.company
    ? "sustainable-proteins"
    : "protein-producer-index",
);

const kpiColor = (
  kpi: string,
  company: TypeCrtCompany | TypeSpCompany | TypePpiCompany,
  max = 100,
) => {
  if (isCompanyKey(kpi, company)) {
    if (company[kpi] === "") return "#bababa";
    if (String(kpi).includes("ALP") && company[kpi] === "0") return "#bababa";
    return colours.riskColour(
      String(kpi).includes("ALP") ? 100 : Number(company[kpi]),
      max,
    );
  }
  return "";
};
</script>

<template>
  <div
    class="c-info absolute text-left"
    :class="{ show }"
  >
    <h4 v-if="'Company' in company">
      {{ company.Company }}
    </h4>
    <h4 v-else>
      {{ company.Name }}
    </h4>

    <!-- Protein Producer Index && Supply Chain Analysis -->
    <div v-if="project.fields.slug == 'protein-producer-index' || project.fields.slug == 'supply-chain-analysis'">
      <table class="w-full text-xs">
        <tr
          v-if="selectedKpi"
          class="c-info__row"
          data-field="Score"
        >
          <td class="w-full text-sm font-semibold">
            {{ year === "2022" ? "2021" : year }} {{ selectedKpi }}
          </td>
          <td>
            <div
              v-if="isCompanyKey(selectedKpi, company)"
              class="min-w-[120px] rounded p-2 text-center font-semibold uppercase text-white"
              :style="{
                backgroundColor: kpiColor(
                  selectedKpi,
                  company,
                  maxScore ? maxScore : 100,
                ),
              }"
            >
              <span v-if="showPillars">
                {{ colours.level(
                  Number(company[selectedKpi]),
                  maxScore ?? 0,
                ) }}
              </span>
              <span v-else>
                {{ Math.round(Number(company[selectedKpi])) }}
              </span>
            </div>
          </td>
        </tr>
      </table>

      <!-- Pillars Score -->
      <table
        v-if="showPillars"
        class="mb-2 mt-4 w-full text-xs"
      >
        <h5 class="font-semibold">
          Pillars Score (Beta)
        </h5>

        <tr
          v-for="(pillar, i) in pillars"
          :key="i"
          class="c-info__row"
          data-field="Score"
        >
          <td class="w-full text-sm font-semibold">
            {{ pillar.key }} Score
          </td>
          <td>
            <div
              class="min-w-[120px] rounded p-2 text-center font-semibold uppercase text-white"
              :style="{
                backgroundColor: pillar.colour,
              }"
            >
              {{ Math.round(Number(company[`${selectedKpi} ${pillar.key}` as keyof typeof company])) }}%
            </div>
          </td>
        </tr>
      </table>

      <table
        v-if="
          'Overall Outlook' in company
            && isCompanyKey(selectedOutlook, company)
            && company[selectedOutlook]
        "
        class="w-full text-xs"
      >
        <tr class="c-info__row">
          <td>{{ year }} Outlook</td>
          <td>
            <svg
              v-if="company[selectedOutlook]"
              id="outlook-icon"
              width="14"
              viewBox="0 0 16 8"
            >
              <polygon
                v-if="company[selectedOutlook] === 'Positive'"
                fill="#6CC62D"
                points="8 0 16 8 0 8"
              />
              <polygon
                v-else-if="company[selectedOutlook] === 'Negative'"
                fill="#FF0000"
                transform="translate(8.000000, 4.000000) scale(1, -1) translate(-8.000000, -4.000000) "
                points="8 0 16 8 0 8"
              />
              <rect
                v-else
                fill="#E6B800"
                width="16"
                height="5"
                rx="2"
              />
            </svg>
            {{ company[selectedOutlook] }}
          </td>
        </tr>
      </table>

      <NuxtLink
        :to="`/resources/companies-assessed/${company.Ticker}/protein-producer-index`"
        class="btn mt-2 w-full"
        @click="
          pushDataLayerEvent('evChartCompanyClick', {
            name: 'Company' in company ? company.Company : company.Name,
          })
        "
      >
        View Full Profile
      </NuxtLink>
    </div>

    <!-- Climate Risk Tool (Breakdown) -->
    <div v-else-if="isCrtCompany(company) && isBarChart">
      <table
        class="w-full text-xs"
      >
        <tr class="c-info__row">
          <td>Cost</td>
          <td>Net Zero Aligned</td>
          <td>BAU</td>
          <td>High</td>
        </tr>
        <tr class="c-info__row">
          <td><span class="mr-[2px] inline-block size-2 rounded-full bg-topic-nutrition" /> Carbon Tax</td>
          <td>
            {{
              company[("Carbon Tax " + year + " Low") as keyof TypeCrtCompany]
            }}%
          </td>
          <td>
            {{
              company[("Carbon Tax " + year + " BAU") as keyof TypeCrtCompany]
            }}%
          </td>
          <td>
            {{
              company[("Carbon Tax " + year + " High") as keyof TypeCrtCompany]
            }}%
          </td>
        </tr>
        <tr class="c-info__row">
          <td><span class="mr-[2px] inline-block size-2 rounded-full bg-topic-emerging-markets" /> Feed</td>
          <td>
            {{ company[("Feed " + year + " Low") as keyof TypeCrtCompany] }}%
          </td>
          <td>
            {{ company[("Feed " + year + " BAU") as keyof TypeCrtCompany] }}%
          </td>
          <td>
            {{ company[("Feed " + year + " High") as keyof TypeCrtCompany] }}%
          </td>
        </tr>
        <tr class="c-info__row">
          <td><span class="mr-[2px] inline-block size-2 rounded-full bg-topic-antibiotics" /> Feed Additives</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>
            {{ company[("Feed Additives " + year + " High") as keyof TypeCrtCompany] }}%
          </td>
        </tr>
        <tr class="c-info__row">
          <td><span class="mr-[2px] inline-block size-2 rounded-full bg-topic-biodiversity" /> Heat Stress</td>
          <td>
            {{
              company[("Heat stress " + year + " Low") as keyof TypeCrtCompany]
            }}%
          </td>
          <td>
            {{
              company[("Heat stress " + year + " BAU") as keyof TypeCrtCompany]
            }}%
          </td>
          <td>
            {{
              company[("Heat stress " + year + " High") as keyof TypeCrtCompany]
            }}%
          </td>
        </tr>
      </table>

      <NuxtLink
        :to="`/resources/companies-assessed/${company.Ticker}/${project.fields.slug}`"
        class="btn mt-2 w-full"
        @click="
          pushDataLayerEvent('evChartCompanyClick', {
            name: company.Company,
          })
        "
      >
        View Full Profile
      </NuxtLink>
    </div>

    <!-- Climate Risk Tool (Range) -->
    <div v-else-if="isCrtCompany(company)">
      <table class="w-full text-xs">
        <tr class="c-info__row">
          <td>Scenario</td>
          <td>Cost Change</td>
          <td>EBIT Margin</td>
        </tr>
        <tr class="c-info__row">
          <td>
            <span class="mr-[2px] inline-block size-2 rounded-full bg-level-best-practice" />
            Net Zero
          </td>
          <td>
            {{ company[("Costs " + year + " Low") as keyof TypeCrtCompany] }}%
          </td>
          <td>
            {{ company[("EBIT " + year + " Low") as keyof TypeCrtCompany] }}%
          </td>
        </tr>
        <tr class="c-info__row">
          <td>
            <span class="mr-[2px] inline-block size-2 rounded-full bg-level-medium" />
            BAU
          </td>
          <td>
            {{ company[("Costs " + year + " BAU") as keyof TypeCrtCompany] }}%
          </td>
          <td>
            {{ company[("EBIT " + year + " BAU") as keyof TypeCrtCompany] }}%
          </td>
        </tr>
        <tr class="c-info__row">
          <td>
            <span class="mr-[2px] inline-block size-2 rounded-full bg-level-high" />
            High Climate Impact
          </td>
          <td>
            {{ company[("Costs " + year + " High") as keyof TypeCrtCompany] }}%
          </td>
          <td>
            {{ company[("EBIT " + year + " High") as keyof TypeCrtCompany] }}%
          </td>
        </tr>
      </table>

      <NuxtLink
        :to="`/resources/companies-assessed/${company.Ticker}/${project.fields.slug}`"
        class="btn mt-2 w-full"
        @click="
          pushDataLayerEvent('evChartCompanyClick', {
            name: company.Company,
          })
        "
      >
        View Full Profile
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.c-info {
  background: white;
  border-radius: 4px;
  padding: 12px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-30%) scale(0.8);
  transition:
    opacity 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    transform 333ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    left 150ms ease,
    bottom 150ms ease;
  transition-delay:
    0.1s,
    0.1s 0;
  z-index: 100;
  width: 320px;
  margin-left: calc(-320px / 2 + 36px);
  margin-bottom: 24px;
  box-shadow: 5px 5px 5px #0000001a;

  &.show {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0) scale(1);
  }

  &__row {
    @apply border-b border-b-ui-grey2;

    td {
      padding: 6px 0;
    }

    &:first-of-type td,
    td:first-child {
      font-weight: 500;
    }
  }
}
</style>
